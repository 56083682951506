<template>
    <div class="app-container">
        <el-form :model="queryParams" ref="queryForm" :inline="true" v-show="showSearch" label-width="68px">
            <el-form-item label="订单编号" prop="orderNumber">
                <el-input
                        v-model="queryParams.orderNumber"
                        placeholder="请输入订单编号"
                        clearable
                        size="small"
                        @keyup.enter.native="handleQuery"
                />
            </el-form-item>
            <el-form-item label="支付流水" prop="orderNo">
                <el-input
                        v-model="queryParams.orderNo"
                        placeholder="请输入订单支付流水"
                        clearable
                        size="small"
                        @keyup.enter.native="handleQuery"
                />
            </el-form-item>
            <el-form-item label="机会ID" prop="opporId">
                <el-input
                        v-model="queryParams.opporId"
                        placeholder="请输入机会ID"
                        clearable
                        size="small"
                        @keyup.enter.native="handleQuery"
                />
            </el-form-item>
            <el-form-item label="商品名称" prop="goodsName">
                <el-input
                        v-model="queryParams.goodsName"
                        placeholder="请输入商品名称"
                        clearable
                        size="small"
                        @keyup.enter.native="handleQuery"
                />
            </el-form-item>
            <el-form-item label="唯一配置" prop="possessId">
                <el-select v-model="queryParams.possessId" placeholder="请选择唯一配置" size="small">
                    <el-option v-for="(item,index) in possessList" :key="index" :label="item.name"
                               :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="商品顾问" prop="adviserAccountId">
                <el-select v-model="queryParams.adviserAccountId" placeholder="选择顾问" filterable clearable
                           size="small">
                    <!--            <el-option label="选择顾问" value=""/>-->
                    <el-option
                            v-for="ad in adviserList"
                            :value="ad.id"
                            :key="ad.id"
                            :label="ad.nickName"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="电话号码" prop="telephone">
                <el-input
                        v-model="queryParams.telephone"
                        placeholder="请输入电话号码"
                        clearable
                        size="small"
                        @keyup.enter.native="handleQuery"
                />
            </el-form-item>
            <el-form-item label="订单状态" prop="status">
                <el-select v-model="queryParams.status" placeholder="选择用户状态" filterable clearable size="small">
                    <el-option label="选择状态" :value="null"/>
                    <el-option label="未成交" value="1"/>
                    <el-option label="已成交" value="2"/>
                </el-select>
            </el-form-item>
            <el-form-item label="支付状态" prop="paymentStatus">
                <el-select v-model="queryParams.paymentStatus" placeholder="选择支付状态" filterable clearable
                           size="small">
                    <el-option label="选择支付状态" :value="null"/>
                    <el-option label="代缴费" value="1"/>
                    <el-option label="完成" value="3"/>
                </el-select>
            </el-form-item>
            <el-form-item label="创建时间" prop="createStarTime">
                <el-date-picker
                        size="mini"
                        v-model="createTimeRange"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="支付时间" prop="payStartTime">
                <el-date-picker
                        size="mini"
                        v-model="payTimeRange"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="部门" prop="deptId">
                <el-cascader
                        :options="deptList"
                        :props="{ multiple: true, checkStrictly: true, label: 'title', value: 'id', children: 'children', emitPath: false}"
                        v-model="checkedDeptIdList"
                        clearable
                        size="mini"></el-cascader>
            </el-form-item>
<!--            <el-form-item label="数据类型" prop="dataType">-->
<!--                <el-select v-model="queryParams.dataType"-->
<!--                           class="condition-select"-->
<!--                           placeholder="请选择"-->
<!--                           size="small">-->
<!--                    <el-option label="全部" :value="null"/>-->
<!--                    <el-option  label="首资" :value="1"/>-->
<!--                    <el-option  label="库存" :value="2"/>-->
<!--                </el-select>-->
<!--            </el-form-item>-->
            <!-- 额外信息 start -->
            <template v-for="(d,index) in tableParams">
                <el-form-item :label="d.label" size="mini">
                    <el-input  v-model="e[d.name]" class="condition-input"></el-input>
<!--                    <el-select v-if="d.name === 'BGFHIGEGCI'" filterable v-model="e[d.name]">-->
<!--                        <el-option v-for="(item,index) in d.tson.dictList" :key="index" :label="item.value" :value="item.value"  />-->
<!--                    </el-select>-->
<!--                    <el-select v-else-if="d.name === 'dataType'" v-model="e[d.name]">-->
<!--                        <el-option v-for="(item,index) in d.tson.dictList" :key="index" :label="item.value" :value="item.key"  />-->
<!--                    </el-select>-->
<!--                    <el-input v-else v-model="e[d.name]" class="condition-input"></el-input>-->
                </el-form-item>
            </template>

            <template v-for="(d,index) in dataTypeParams">
                <el-form-item v-if="isShowDataType" :label="d.label" size="mini">
                    <el-select v-model="e[d.name]">
                        <el-option v-for="(item,index) in d.tson.tables" :key="index" :label="item.label"
                                   :value="item.value"/>
                    </el-select>
                </el-form-item>
            </template>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
                <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
            </el-form-item>
        </el-form>

        <el-row :gutter="10" class="mb8">
            <el-button v-permission="'crm:order:export'" type="primary" size="mini" @click="exportOrder">导出
            </el-button>
            <el-button v-permission="'crm:order:other'" type="primary" size="mini" @click="otherOrder">外部订单
            </el-button>
            <el-button v-permission="'crm:order:create'" type="primary" size="mini" @click="CreateManuallyOrder">创建订单
            </el-button>
        </el-row>

        <el-table max-height="530" v-loading="loading" :data="ordersList" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55" align="center"/>

            <el-table-column type="expand">
                <template slot-scope="scope">
                    <el-form label-position="left" inline class="demo-table-expand" >
                        <el-col :span="12" v-for="(item,index) in tableColumn" :key="index">
                            <el-form-item :label="item.label" style="width: 100%">
                                <div v-if="item.type === 'INPUT'">{{ scope.row[item.name] }}</div>
                                <div v-if="item.type === 'SELECT'">
                                    <div v-for="t in item.tson.tables" v-if="t.value === scope.row[item.name]">
                                        {{ t.label }}
                                    </div>
                                </div>
<!--                                <div v-if="item.type === 'EXTRA' && item.name === 'dataType'">{{-->
<!--                                        scope.row[item.name]-->
<!--                                    }}-->
<!--                                </div>-->
                                <div v-if="item.type === 'EXTRA' && item.name === 'dataType'">
                                    <span v-if="scope.row[item.name] === 1">首资</span>
                                    <span v-if="scope.row[item.name] === 2">库存</span>
                                </div>
                                <div v-if="item.type === 'EXTRA' && scope.row.tson">{{
                                        scope.row.tson[item.name]
                                    }}
                                </div>
                            </el-form-item>
                        </el-col>
                    </el-form>
                </template>
            </el-table-column>

            <el-table-column label="机会ID" prop="opporId"></el-table-column>
            <el-table-column label="商品名称" prop="goodsName"></el-table-column>
            <el-table-column label="下单商品" prop="extra">
                <template slot-scope="scope">
                    <span>{{
                            JSON.parse(scope.row.extra) === null ? null : JSON.parse(scope.row.extra).BGFHDAJEFH
                        }}</span>
                </template>
            </el-table-column>
            <el-table-column label="商品顾问" align="center" prop="nickName"/>
            <el-table-column label="原价" align="center" prop="amountReceivable"/>
            <el-table-column label="缴费金额(已交)" align="center" prop="paymentAmount"/>
            <el-table-column label="已退金额" align="center" prop="refundAmount"/>
            <el-table-column label="实收金额" align="center" prop="incomeAmount"/>
            <el-table-column label="支付类型" align="center" prop="paymentType">
                <template slot-scope="scope">
                    <span v-if="scope.row.paymentType === 'FREE'">免费</span>
                    <span v-else-if="scope.row.paymentType === 'WEIXIN'">微信支付</span>
                    <span v-else-if="scope.row.paymentType === 'GIVING'">后台开课</span>
                    <span v-else-if="scope.row.paymentType === 'ALIPAY'">支付宝支付</span>
                    <span v-else-if="scope.row.paymentType === 'IOS'">苹果支付</span>
                    <span v-else>其他</span>
                </template>
            </el-table-column>
            <el-table-column label="订单状态" align="center" prop="status" width="120">
                <template slot-scope="scope">
                    <span v-if="scope.row.status === 0">订单</span>
                    <span v-else-if="scope.row.status === 1">未成交</span>
                    <span v-else-if="scope.row.status === 2">已成交</span>
                    <span v-else>其他</span>
                </template>
            </el-table-column>
            <el-table-column label="支付状态" align="center" prop="paymentStatus" width="120">
                <template slot-scope="scope">
                    <span v-if="scope.row.paymentStatus === 1">待缴费</span>
                    <span v-else-if="scope.row.paymentStatus === 2">欠费</span>
                    <span v-else-if="scope.row.paymentStatus === 3">完成</span>
                    <span v-else-if="scope.row.paymentStatus === 4">已退费</span>
                    <span v-else-if="scope.row.paymentStatus === 5">已取消</span>
                    <span v-else-if="scope.row.paymentStatus === 6">已关闭</span>
                    <span v-else>其他</span>
                </template>
            </el-table-column>
            <el-table-column label="操作" fixed="right" width="200" align="center" class-name="small-padding">
                <template v-slot="scope">
                    <el-button v-if="scope.row.manuallyStatus === 'INIT'" v-permission="'crm:order:create'"
                               type="primary"
                               size="mini"
                               @click="updateManuallyOrder(scope.row)">修改
                    </el-button>
                    <el-button v-if="scope.row.manuallyStatus === 'SUCCESS'" v-permission="'crm:order:create'"
                               type="primary"
                               size="mini"
                               @click="callbackManuallyOrder(scope.row.id)">撤回
                    </el-button>
                    <el-button v-if="scope.row.status === 2 || scope.row.status === 4"
                               v-permission="'crm:order:refund'"
                               type="primary"
                               size="mini"
                               @click="refundOrder(scope.row)">退款
                    </el-button>
                    <el-button v-if="scope.row.status === 4"
                               v-permission="'crm:order:refund'"
                               type="primary"
                               size="mini"
                               plain
                               @click="queryRefundLog(scope.row.orderNumber)">退款记录
                    </el-button>
                </template>
            </el-table-column>
        </el-table>

        <pagination
                v-show="total>0"
                :total="total"
                :page.sync="queryParams.page"
                :limit.sync="queryParams.limit"
                @pagination="getList"
        />
        <el-dialog
                title="外部订单"
                :visible.sync="othervisible"
                width="80%"
                top="5vh"
                :show-close="false">
            <ExternalOrder v-if="othervisible" :othervisible="othervisible"></ExternalOrder>
        </el-dialog>

        <el-dialog
                title="新建订单"
                :visible.sync="createOrderVisible"
                width="80%"
                top="5vh">
            <el-form ref="form" :model="form" :rules="rules" label-width="200px">
                <!--        <el-form-item label="是否成单(机会成单状态)" prop="over">-->
                <!--          <el-select v-model="form.over" filterable class="condition-select" placeholder="请选择">-->
                <!--            <el-option label="成单" :value="1"></el-option>-->
                <!--            <el-option label="未成单" :value="0"></el-option>-->
                <!--          </el-select>-->
                <!--        </el-form-item>-->
                <el-form-item label="订单号" prop="orderNumber">
                    <el-input v-model="form.orderNumber"/>
                </el-form-item>
                <el-form-item label="支付流水号" prop="orderNo">
                    <el-input v-model="form.orderNo"/>
                </el-form-item>
                <el-form-item label="手机号" prop="studentPhone">
                    <el-row :span="24">
                        <el-col :span="18">
                            <el-input v-model="form.studentPhone"/>
                        </el-col>
                        <el-col :span="6" style="text-align: center">
                            <el-button type="success" size="mini" @click="checkOrder">检查订单</el-button>
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item label="成单学习顾问" prop="adviserAccountId">
                    <el-row :span="24">
                        <el-col :span="18">
                            <el-select v-model="form.adviserAccountId" filterable class="condition-select"
                                       placeholder="请选择">
                                <el-option label="全部" :value="null"></el-option>
                                <el-option v-for="item in adviserList" :key="item.id" :label="item.nickName"
                                           :value="item.id">
                                </el-option>
                            </el-select>
                        </el-col>
                        <el-col :span="6" style="text-align: center">
                            <span style="color: red;font-size: 4px;">请先点击检查订单,才能点击提交</span>
                        </el-col>
                    </el-row>
                </el-form-item>

                <!--显示数据类型-->
                <template v-for="(d,index) in dataTypeParams">
                    <el-form-item v-if="isShowDataType" :label="d.label" size="mini">
                        <el-select v-model="form.dataType">
                            <el-option v-for="(item,index) in d.tson.tables"
                                       :key="index"
                                       :label="item.label"
                                       :value="item.value"/>
                        </el-select>
                    </el-form-item>
                </template>
<!--                <el-form-item label="数据类型" prop="dataType">-->
<!--                    <el-row :span="24">-->
<!--                        <el-col :span="18">-->
<!--                            <el-select v-model="form.dataType" filterable class="condition-select"-->
<!--                                       placeholder="请选择">-->
<!--&lt;!&ndash;                                <el-option label="全部" :value="null"/>&ndash;&gt;-->
<!--                                <el-option  label="首资" :value="1"/>-->
<!--                                <el-option  label="库存" :value="2"/>-->
<!--                            </el-select>-->
<!--                        </el-col>-->
<!--                        <el-col :span="6" style="text-align: center">-->
<!--                            <span style="color: red;font-size: 4px;">请先点击检查订单,才能点击提交</span>-->
<!--                        </el-col>-->
<!--                    </el-row>-->
<!--                </el-form-item>-->
                <el-form-item label="商品名称" prop="goodsName">
                    <el-input v-model="form.goodsName"/>
                </el-form-item>
                <el-form-item label="原价" prop="amountReceivable">
                    <el-input type="number" v-model="form.amountReceivable"/>
                </el-form-item>
                <el-form-item label="实付" prop="paymentAmount">
                    <el-input type="number" v-model="form.paymentAmount"/>
                </el-form-item>
                <el-form-item prop="orderChannel">
                    <el-tooltip class="item" effect="dark" content="财务对账渠道平台" placement="top" slot="label">
                        <span>付款渠道</span></el-tooltip>
                    <el-tooltip class="item" effect="dark" content="财务对账渠道平台" placement="top">
                        <el-select v-model="form.orderChannel">
                            <el-option value="DOUYIN" label="抖音"></el-option>
                            <el-option value="XIAOETONG" label="小鹅通"></el-option>
                            <el-option value="WANGXIAO" label="网校"></el-option>
                            <el-option value="STAGES" label="分期平台"></el-option>
                            <el-option value="THIRD_PARTY" label="第三方扫码"></el-option>
                            <el-option value="COMPANY" label="对公转账"></el-option>
                            <el-option value="PRIVATE" label="线下转账"></el-option>
                            <el-option value="OTHER" label="其他"></el-option>
                        </el-select>
                    </el-tooltip>
                </el-form-item>
                <el-form-item label="支付方式" prop="paymentType">
                    <el-select v-model="form.paymentType">
                        <el-option value="WEIXIN" label="微信支付"></el-option>
                        <el-option value="ALIPAY" label="支付宝支付"></el-option>
                        <el-option value="GIVING" label="后台开课"></el-option>
                        <el-option value="FREE" label="免费"></el-option>
                        <el-option value="COMPANY" label="对公转账"></el-option>
                        <el-option value="STAGES" label="第三方分期"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="支付时间" prop="paymentTime">
                    <el-date-picker
                            v-model="form.paymentTime"
                            type="datetime"
                            placeholder="选择日期时间"
                            align="right">
                    </el-date-picker>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="saveManuallyOrder" :disabled="disCreate">确 定</el-button>
                <el-button @click="createOrderVisible = false;form = {}">取 消</el-button>
            </div>
        </el-dialog>

        <!--  新建机会  -->
        <TheAddOpportunity
            :pond-id="1"
                :subject-list="subjectList"
                :possess-list="possessList"
                :is-show-add-oppor="isShowAddOppor">
        </TheAddOpportunity>

        <!--  展示机会历史订单  -->
        <el-drawer
                title="机会历史订单"
                :visible.sync="historyOrderVisible"
                size="500"
                direction="btt">
            <el-table :data="historyOrders" height="400px">
                <el-table-column property="orderNumber" label="订单号"></el-table-column>
                <el-table-column property="goodsName" label="商品名称"></el-table-column>
                <el-table-column property="nickName" label="学习顾问"></el-table-column>
                <el-table-column property="paymentStatusCell" label="支付状态"></el-table-column>
                <el-table-column property="paymentAmount" label="支付金额"></el-table-column>
                <el-table-column property="statusCell" label="订单状态"></el-table-column>
                <el-table-column property="createTime" label="订单时间"></el-table-column>
            </el-table>
        </el-drawer>

        <RefundRepairDetail
                v-if="openRefundDetail"
                :refund-status="0"
                :open-refund-detail="openRefundDetail"
                :adviser-list="adviserList"
                :order-number="orderNumber"
                @changeDetail="closeRefundDetail()"/>

        <el-dialog title="退款记录" :visible.sync="refundSyncDataDialogTableVisible" v-if="refundSyncDataDialogTableVisible">
            <refund-sync-data :order-number="orderNumber" :adviser-list="adviserList"/>
        </el-dialog>
    </div>
</template>

<script>
import ExternalOrder from "@/components/order/ExternalOrder";
import * as ordersApi from "@/api/crm/orders";
import axios from "axios";
import * as deployApi from "@/api/system/searchDeploy"
import * as api from "@/utils/api";
import TheAddOpportunity from "@/components/opportunity/TheAddOpportunity.vue";
import * as accountApi from "@/api/system/account";
import * as deptApi from "@/api/system/dept";
import RefundRepairDetail from "@/components/order/RefundRepairDetail.vue";
import RefundSyncData from "@/views/crm/orders/RefundSyncData.vue"

export default {
    name: "Orders",
    components: {
        RefundSyncData,
        RefundRepairDetail,
        ExternalOrder,
        TheAddOpportunity
    },
    data() {
        return {
            isShowDataType: false,
            historyOrders: [],
            subjectList: [],
            possessList: [],
            createOrderVisible: false,
            // 遮罩层
            loading: true,
            // 导出遮罩层
            exportLoading: false,
            // 选中数组
            ids: [],
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 显示搜索条件
            showSearch: true,
            // 总条数
            total: 0,
            // 参数配置表格数据
            ordersList: [],
            // 弹出层标题
            title: "",
            // 是否显示弹出层
            open: false,
            // 查询参数
            queryParams: {
                page: 1,
                limit: 10,
                orderNumber: null,
                orderNo: null,
                opporId: null,
                goodsName: null,
                possessId: null,
                adviserAccountId: null,
                telephone: null,
                status: '',
                paymentStatus: '',
                createStarTime: null,
                createEndTime: null,
                payStartTime: null,
                payEndTime: null,
                deptIdList: []
            },
            createTimeRange: null,
            payTimeRange: null,
            // 表单参数
            form: {},
            // 表单校验
            rules: {
                // over: [{required: true, message: '成单状态确认', trigger: 'blur'}],
                orderNumber: [{required: true, message: '输入订单编号', trigger: 'blur'}],
                orderNo: [{required: true, message: '输入流水单号', trigger: 'blur'}],
                studentPhone: [{required: true, message: '输入手机号', trigger: 'blur'},
                    {min: 11, max: 11, message: "请输入正确的手机号", trigger: 'blur'}],
                adviserAccountId: [{required: true, message: '选择学习顾问', trigger: 'blur'}],
                // dataType: [{required: true, message: '请选择数据类型', trigger: 'blur'}],
                goodsName: [{required: true, message: '输入商品名称', trigger: 'blur'}],
                amountReceivable: [{required: true, message: '确定商品价格', trigger: 'blur'}],
                paymentAmount: [{required: true, message: '确定实付金额', trigger: 'blur'}],
                orderChannel: [{required: true, message: '请选择付款渠道', trigger: 'blur'}],
                paymentType: [{required: true, message: '选择支付方式', trigger: 'blur'}],
                paymentTime: [{required: true, message: '选择支付时间', trigger: 'blur'}],
            },
            adviserList: [],
            paymentStatus: [
                {value: 1, label: "待缴费"},
                {value: 2, label: "欠费"},
                {value: 3, label: "完成"},
                {value: 4, label: "已退费"},
                {value: 5, label: "已取消"},
                {value: 6, label: "关闭流水"},
                {value: 7, label: "延期"},
                {value: 8, label: "发货"},
                {value: 9, label: "支付成功但未拼团成功"},
            ],
            status: [
                {value: 0, label: "未通过"},
                {value: 1, label: "审核中"},
                {value: 2, label: "通过"}
            ],
            othervisible: false,
            innerOthervisible: false,
            externalOrder: {},
            tableColumn: [],
            e: {},
            tableParams: [],
            dataTypeParams: [],
            isShowAddOppor: {
                isShow: false
            },
            historyOrderVisible: false,
            disCreate: true,
            deptList: [],
            checkedDeptIdList: [],
            openRefundDetail: false,
            orderNumber: '',
            refundSyncDataDialogTableVisible: false,
        };
    },
    created() {
        this.findColumn()
        this.getList();
        this.getPossessList()
        this.getAdviserList()
        this.findDept()
        // 注释 查询显示数据类型
        // this.findSearch();
    },
    methods: {
        closeRefundDetail() {
            this.openRefundDetail = false
        },
        queryRefundLog(orderNumber) {
            console.log(orderNumber, 'fdsfs')
            this.orderNumber = orderNumber;
            this.refundSyncDataDialogTableVisible = true;
        },
        refundOrder(val) {
            console.log(val, 111)
            this.openRefundDetail = true
            this.orderNumber = val.orderNumber
        },
        findDept() {
            deptApi.treeDept().then(res => {
                this.deptList = res.data
            })
        },
        callbackManuallyOrder(id) {
            ordersApi.callbackManuallyOrder(id).then(res => {
                if (res.success) {
                    this.$message.success("撤回成功")
                    this.getList();
                } else {
                    this.$message.warning(res.msg)
                }
            });
        },
        updateManuallyOrder(row) {
            // this.getAdviserList();
            this.form = row
            this.createOrderVisible = true;
        },
        checkOrder() {
            if (this.form.studentPhone) {
                this.historyOrderVisible = true
                this.disCreate = false
                ordersApi.historyOrdersByTelephone(this.form.studentPhone).then(res => {
                    if (res.success) {
                        this.historyOrders = res.data
                        this.$message.success("请对比数据,确认是否可以提交")
                    } else {
                        this.$message.warning(res.msg)
                    }
                })
            } else {
                this.$message.warning("请输入手机号码")
            }
        },
        /**
         * 打开
         */
        openAddOppor() {
            this.getSubjectList()
            this.isShowAddOppor.isShow = true
        },
        /**
         * 获取专业列表
         */
        getSubjectList: function () {
            api.subjectList().then(res => {
                if (res.success) {
                    this.subjectList = res.data
                }
            })
        },
        /**
         * 获取唯一值配置
         */
        getPossessList() {
            api.possessList().then(res => {
                this.possessList = res.data
            })
        },
        /**
         * 保存手动订单
         */
        saveManuallyOrder() {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    if (this.isShowDataType && !this.form.dataType){
                        return this.$message.warning("请选择数据类型")
                    }
                    if (this.form.id) {
                        api.UpdateManuallyOrder(this.form).then(res => {
                            //对于机会不存在进行特殊处理
                            if (res.code === 300) {
                                this.$message.warning("手机号未入库,请先创建机会")
                                console.log(300)
                                this.openAddOppor()
                                return false;
                            }
                            if (res.success) {
                                this.createOrderVisible = false;
                                this.$message.success(res.msg)
                                this.getList();
                            } else {
                                this.$message.warning(res.msg)
                            }

                        })
                    } else {
                        api.CreateManuallyOrder(this.form).then(res => {
                            //对于机会不存在进行特殊处理
                            if (res.code === 300) {
                                this.$message.warning("手机号未入库,请先创建机会")
                                console.log(300)
                                this.openAddOppor()
                                return false;
                            }
                            if (res.success) {
                                this.createOrderVisible = false;
                                this.$message.success(res.msg)
                                this.getList();
                            } else {
                                this.$message.warning(res.msg)
                            }

                        })
                    }
                } else {
                    this.$message.warning("请确认数据!")
                    return false;
                }
            });
        },
        /**
         * 创建手动订单
         * @constructor
         */
        CreateManuallyOrder() {
            this.form = {}
            // this.getAdviserList();
            this.createOrderVisible = true;
            this.disCreate = true
        },
        /**
         * 获取学习顾问列表
         */
        getAdviserList: function () {
            accountApi.adviserList().then(res => {
                if (res.success) {
                    this.adviserList = res.data
                }
            })
        },
        //获取列表表头以及查询字段
        findColumn() {
            deployApi.findByClassify("ORDER_TABLES").then(res => {
                this.tableParams = []
                for (let e of res.data) {
                    if (e.data) {
                        e.tson = JSON.parse(e.data)
                    }
                    if (e.array) {
                        this.tableColumn.push(e)
                    }
                    if (e.find){
                        this.tableParams.push(e)
                    }
                }
            })
        },
        /**
         * 获取数据类型字段
         */
        findSearch() {
            deployApi.findByClassify("ORDER_TABLES").then(res => {
                this.dataTypeParams = []
                for (let e of res.data) {
                    if (e.status && e.name ==='dataType' && e.data && e.type === 'SELECT') {
                        e.tson = JSON.parse(e.data)
                            this.dataTypeParams.push(e)
                            // 让数据类型显示
                            this.isShowDataType = e.array
                    }
                }
            })
        },
        //其他订单
        otherOrder() {
            this.othervisible = true
        },
        sendMessage(e) {
            ordersApi.sendMessage(e.orderNumber).then(res => {
                if (res.success) {
                    this.msgSuccess("消息发送成功");
                } else {
                    this.$message.warning(res.msg)
                }
            });
        },
        //新增订单
        addOther() {
            this.innerOthervisible = true
        },
        /** 查询参数配置列表 */
        getList() {
            this.loading = true;
            if (this.createTimeRange && this.createTimeRange.length > 0) {
                this.queryParams.createStarTime = this.createTimeRange[0] + " 00:00:00";
                this.queryParams.createEndTime = this.createTimeRange[1] + " 59:59:59";
            }
            if (this.payTimeRange && this.payTimeRange.length > 0) {
                this.queryParams.payStartTime = this.payTimeRange[0] + " 00:00:00";
                this.queryParams.payEndTime = this.payTimeRange[1] + " 59:59:59";
            }
            this.queryParams.data = JSON.stringify(this.e)
            if (this.checkedDeptIdList) {
                this.queryParams.deptIds = this.checkedDeptIdList.join(',')
            }
            ordersApi.listOrders(this.queryParams).then(response => {
                if (response.success) {
                    for (let e of response.data.records) {
                        if (e.extra) {
                            e.tson = JSON.parse(e.extra)
                        }
                    }
                    this.ordersList = response.data.records;
                    this.total = response.data.total;
                } else {
                    this.$message.error(response.msg)
                }
                this.loading = false;
            });
        },
        // 取消按钮
        cancel() {
            this.open = false;
            this.reset();
        },
        // 表单重置
        reset() {
            this.form = {
                configId: null,
                configName: null,
                configKey: null,
                configValue: null,
                configType: null,
                createBy: null,
                createTime: null,
                updateBy: null,
                updateTime: null,
                remark: null
            };
            this.resetForm("form");
        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.queryParams.page = 1;
            this.getList();
        },
        /** 重置按钮操作 */
        resetQuery() {
            this.resetForm("queryForm");
            this.payTimeRange = []
            this.createTimeRange = []
            this.checkedDeptIdList = [];
            this.e = {}
            this.handleQuery();
        },
        // 多选框选中数据
        handleSelectionChange(selection) {
            this.ids = selection.map(item => item.configId)
            this.single = selection.length !== 1
            this.multiple = !selection.length
        },
        //导出订单
        exportOrder() {
            if (!this.createTimeRange && !this.payTimeRange) {
                this.$message.warning("请先选择创建时间或支付时间区间")
                return false
            }
            let createTimeStart, createTimeEnd, payTimeStart, payTimeEnd
            if (this.createTimeRange && this.createTimeRange.length > 0) {
                createTimeStart = this.createTimeRange[0]
                createTimeEnd = this.createTimeRange[1]
                let createTimeStartD = new Date(Date.parse(createTimeStart.replace(/-/g, "/")));
                let createTimeEndD = new Date(Date.parse(createTimeEnd.replace(/-/g, "/")));
                let createTimeDays = parseInt((createTimeEndD.getTime() - createTimeStartD.getTime()) / (1000 * 60 * 60 * 24));
                if (createTimeDays > 62) {
                    this.$message.warning("创建时间跨度设置多于62天，请重新设置导出时间跨度");
                    return false;
                }
                this.queryParams.createStarTime = this.createTimeRange[0] + " 00:00:00";
                this.queryParams.endStartTime = this.createTimeRange[1] + " 59:59:59";
            }
            if (this.payTimeRange && this.payTimeRange.length > 0) {
                payTimeStart = this.payTimeRange[0]
                payTimeEnd = this.payTimeRange[1]
                let payTimeStartD = new Date(Date.parse(payTimeStart.replace(/-/g, "/")));
                let payTimeEndD = new Date(Date.parse(payTimeEnd.replace(/-/g, "/")));
                let payTimeDays = parseInt((payTimeEndD.getTime() - payTimeStartD.getTime()) / (1000 * 60 * 60 * 24));
                if (payTimeDays > 62) {
                    this.$message.warning("支付时间跨度设置多于62天，请重新设置导出时间跨度");
                    return false;
                }
                this.queryParams.payStartTime = this.payTimeRange[0] + " 00:00:00";
                this.queryParams.payEndTime = this.payTimeRange[1] + " 59:59:59";

            }

            axios({
                method: "post",
                url: "/api/manage/crm/order/exportOrder",
                data: this.queryParams,
                responseType: "blob",
                headers: {
                    "Content-Type": "application/json;charset=utf-8",
                    "version": localStorage.getItem("_version"),
                },
            }).then(res => {
                const blob = new Blob([res.data]);
                const fileName = '订单.xlsx';
                const elink = document.createElement('a')
                elink.download = fileName;
                elink.style.display = 'none';
                elink.href = URL.createObjectURL(blob);
                document.body.appendChild(elink);
                elink.click();
                URL.revokeObjectURL(elink.href); // 释放URL 对象
                document.body.removeChild(elink);
            })

        },
        exportOrderMore() {
            axios({
                method: "post",
                url: "/api/manage/crm/order/exportOrderMore",
                data: JSON.stringify(this.queryParams),
                responseType: "blob",
                headers: {
                    "Content-Type": "application/json;charset=utf-8",
                    "version": localStorage.getItem("_version"),
                },
                timeout: 300000
            }).then(res => {
                const blob = new Blob([res.data]);
                const fileName = '订单.xlsx';
                const elink = document.createElement('a')
                elink.download = fileName;
                elink.style.display = 'none';
                elink.href = URL.createObjectURL(blob);
                document.body.appendChild(elink);
                elink.click();
                URL.revokeObjectURL(elink.href); // 释放URL 对象
                document.body.removeChild(elink);
            })
        }
    }
};
</script>
<style scoped>
.demo-table-expand {
    font-size: 0;
}

/deep/ .demo-table-expand label {
    color: #99a9bf;
}

.demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 50%;
}
</style>
