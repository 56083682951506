<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" :inline="true" v-show="showSearch" @submit.native.prevent>
      <el-form-item label="部门名称" prop="deptName">
        <el-input
            v-model="queryParams.deptName"
            placeholder="请输入部门名称"
            clearable
            size="small"
            @keyup.enter.native="handleQuery"
            @submit.native.prevent="false"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>

    <el-row :gutter="10" class="mb8">
      <el-col :span="1.5">
        <el-button
            type="primary"
            plain
            icon="el-icon-plus"
            size="mini"
            @click="handleAdd"
        >新增
        </el-button>
      </el-col>
    </el-row>

    <el-table
        v-loading="loading"
        :data="deptList"
        row-key="id"
        default-expand-all
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
    >
      <el-table-column prop="deptName" label="部门名称"></el-table-column>
      <el-table-column prop="orderNum" label="排序"></el-table-column>
      <el-table-column prop="leaderId" label="负责人">
        <template v-slot="scope">
          <el-tag v-for="item in adviserList" :key="item.id" v-if="scope.row.leaderId == item.id">{{ item.nickName }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="phone" label="联系电话"></el-table-column>
      <el-table-column prop="status" label="状态">
        <template v-slot="scope">
          <span v-if="scope.row.status">启用</span>
          <span v-if="!scope.row.status">停用</span>
        </template>
      </el-table-column>
      <el-table-column label="创建时间" align="center" prop="createTime"></el-table-column>
      <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button
              size="mini"
              type="text"
              icon="el-icon-edit"
              @click="handleUpdate(scope.row)"
          >修改
          </el-button>
          <el-button
              size="mini"
              type="text"
              icon="el-icon-plus"
              @click="handleAdd(scope.row)"
          >新增
          </el-button>
          <el-button
              v-if="scope.row.parentId != 0"
              size="mini"
              type="text"
              icon="el-icon-delete"
              @click="handleDelete(scope.row)"
          >删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 添加或修改部门对话框 -->
    <el-dialog :title="title" :visible.sync="open" width="600px" append-to-body>
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-row>
          <el-col :span="24" v-if="form.parentId !== 0">
            <el-form-item label="上级部门" prop="parentId">
              <treeselect v-model="form.parentId" :options="deptList" :normalizer="normalizer"
                          :defaultExpandLevel="Infinity"
                          placeholder="选择上级部门"/>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="部门名称" prop="deptName">
              <el-input v-model="form.deptName" placeholder="请输入部门名称"/>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="显示排序" prop="orderNum">
              <el-input-number v-model="form.orderNum" controls-position="right" :min="0"/>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="负责人" prop="leaderId">
              <el-select v-model="form.leaderId">
                <el-option :value="item.id" v-for="item in adviserList" :key="item.id"
                           :label="item.nickName"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系电话" prop="phone">
              <el-input v-model="form.phone" placeholder="请输入联系电话" maxlength="11"/>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="部门状态">
              <el-radio-group v-model="form.status">
                <el-radio
                    v-for="status in statusOptions"
                    :key="status.value"
                    :label="status.value"
                >{{ status.label }}
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="光荣展示">
              <el-radio-group v-model="form.ranking">
                <el-radio
                    v-for="status in rankingOptions"
                    :key="status.value"
                    :label="status.value"
                >{{ status.label }}
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="光荣小组">
              <el-radio-group v-model="form.rankingTeam">
                <el-radio
                    v-for="status in teamOptions"
                    :key="status.value"
                    :label="status.value"
                >{{ status.label }}
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="光荣人数" prop="rankingNum">
              <el-input-number v-model="form.rankingNum" controls-position="right" :min="0"/>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="唯一配置" prop="optionList">
              <el-tooltip effect="dark" placement="bottom" slot="label">
                <div slot="content">
                  <span>下级勾选,上级也会拥有权限;下级拥有权限,上级无法取消</span>
                </div>
                <span>唯一配置</span>
              </el-tooltip>
              <el-checkbox-group size="mini" v-model="form.possessList">
                <el-checkbox-button v-for="poss in possList" :label="poss.id" :key="poss.id">{{ poss.name }}
                </el-checkbox-button>
              </el-checkbox-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as deptApi from "@/api/system/dept";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import * as possessApi from "@/api/crm/possess";
import * as api from "@/utils/api";
import * as accountApi from "@/api/system/account";

export default {
  name: "Dept",
  components: {Treeselect},
  data() {
    return {
      adviserList: [],
      optionList: [],
      possList: [],
      // 遮罩层
      loading: true,
      // 显示搜索条件
      showSearch: true,
      // 表格树数据
      deptList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 状态数据字典
      statusOptions: [
        {value: 1, label: "正常"},
        {value: 0, label: "暂停"}
      ],
      teamOptions: [
        {value: true, label: "展示"},
        {value: false, label: "隐藏"}
      ],
      rankingOptions: [
        {value: 1, label: "全部"},
        {value: 0, label: "仅小组"}
      ],
      // 查询参数
      queryParams: {
        deptName: null,
        status: null
      },
      // 表单参数
      form: {
        possessList: []
      },
      // 表单校验
      rules: {
        parentId: [
          {required: true, message: "上级部门不能为空", trigger: "blur"}
        ],
        deptName: [
          {required: true, message: "部门名称不能为空", trigger: "blur"}
        ],
        orderNum: [
          {required: true, message: "菜单顺序不能为空", trigger: "blur"}
        ],
        phone: [
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: "请输入正确的手机号码",
            trigger: "blur"
          }
        ]
      }
    };
  },
  created() {
    this.getList();
    this.getAdviserList()
  },
  methods: {
    /**
     * 获取学习顾问列表
     */
    getAdviserList: function () {
      accountApi.adviserList().then(res => {
        if (res.success) {
          this.adviserList = res.data
        }
      })
    },
    getPossess() {
      possessApi.list().then(res => {
        this.possList = res.data;
      });
    },
    /** 查询部门列表 */
    getList() {
      this.loading = true;
      deptApi.listDept(this.queryParams).then(response => {
        this.deptList = this.handleTree(response.data, "id");
        this.loading = false;
      });
    },
    /** 转换部门数据结构 */
    normalizer(node) {
      if (node.children && !node.children.length) {
        delete node.children;
      }
      return {
        id: node.id,
        label: node.deptName,
        children: node.children
      };
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {
        possessList: [],
        orderNum: 0,
        status: 1,
        rankingNum: 5,
        rankingTeam: 0,
        ranking: false
      };
      this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset();
      if (row != undefined) {
        this.form.parentId = row.id;
      }
      this.open = true;
      this.title = "添加部门";
      this.getPossess()
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      deptApi.getDept(row.id).then(response => {
        this.form = response.data;
        this.open = true;
        this.title = "修改部门";
        this.getPossess()
      });
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs["form"].validate(valid => {
        if (valid) {
          deptApi.saveDept(this.form).then(response => {
            if (response.success) {
              this.msgSuccess("新增成功");
              this.open = false;
              this.getList();
            } else {
              this.$message.warning(response.msg)
            }
          });
        }
      });
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      this.$confirm('是否确认删除名称为"' + row.deptName + '"的数据项?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        return deptApi.deleteDept(row.id);
      }).then((res) => {
        console.log(res)
        if (res.success) {
          this.msgSuccess("删除成功");
        } else {
          this.$message.warning(res.msg);
        }
        this.getList();
      })
    }
  }
};
</script>
